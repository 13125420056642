$floaty-bg: rgba(255, 255, 0, 0.5);

div.Home {
	z-index: 10;
	@include blorder();
	width: $basewidth;
	margin: 0 auto;
	padding: 10px;
	// box-shadow: 0 0 30px rgba(255, 255, 255, 1);
	// background-color: rgba(255, 255, 255, 0.5);
	// border-radius: 40px;
	box-sizing: border-box;

	> * {
		padding: 10px;
	}
	hr {
		padding: 0;
	}
	h1 {
		border-top: 1px solid $neutral-bg-color;
		margin: 30px 0 0;
	}
	div.anchor {
		display: none;
	}
	.intro {
		margin: 30px 0 0 0;
			@media (max-width: 900px) {
margin: 0;
padding: 0;
}
	}
	section {
		padding: 40px 0;
	}
}

.homehead {
	z-index: 11;
	// position: fixed;
	// top: 0;

	// min-height: 85px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	background-color: rgb(28, 42, 70);
	color: $sidebar-bg-color;
	letter-spacing: 1px;
	padding: 15px;
	box-sizing: border-box;

	@media (max-width: 700px) {
	}
	.homenav {
		width: $basewidth;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.homelinks {
			@include blorder();
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;
			margin: auto 0;
			a {
				color: inherit;
				text-decoration: none;
				box-sizing: border-box;
				max-width: 100%;
				padding: 7px;
				h2 {
					margin: 0;
				}
				transition: all ease-in-out 0.15s;
				text-decoration: none;
				border-bottom: 2px solid transparent;
				&:hover {
					border-color: $sidebar-bg-color;
				}
			}
			@media (max-width: 1000px) {
				justify-content: flex-start;
				margin: 10px 0
			}
		}
		
		.logo {
			h1 {
				font-size: 3.6rem;
				margin: 0;
				border: 0;
			}
			p {
				font-size: 1.4rem;
				line-height: 1rem;
				margin: 3px 0;
			}
		}

		@media (max-width: 700px) {
			flex-direction: column;
			justify-content: space-evenly;
			& > * {
				margin: 10px 0 10px;
			}
			.logo {
				text-align: left;
				padding-left: 7px;
			}
			.navlinks {
				margin: 10px 0 0 0;
			}
		}
	}
}

div.cover {
	z-index: 0;
	position: absolute;
	margin-top: -25px;
	height: 120%;
	width: 6000px;
	background-image: url("../../imgs/floatbg.jpg");
	background-size: auto 100%;
	animation: move 250s infinite linear;
	will-change: transform;
	opacity: 0.1;
	overflow: hidden;
	@media (max-width: 900px) {
		overflow: hidden;
	}
}

div.mainbg {
	z-index: 1;
	position: absolute;
	height: 100%;
	width: 100%;

	// background-image: linear-gradient(desaturate($floaty-bg, 50%), lighten($floaty-bg, 30%));
	background-image: linear-gradient(desaturate(darken($floaty-bg, 30%), 0%), lighten($floaty-bg, 50%));
}

@keyframes move {
	0% {
		transform: translateY(0px) translateX(1500px);
	}
	50% {
		transform: translateY(0px) translateX(-1500px);
	}
	100% {
		transform: translateY(0) translateX(1500px);
	}
}

@media (max-width: 900px) {
	div.Home {
		margin: 0 auto;
		width: 94%;
		// max-width: 900px;
	}
}

@media (max-width: 700px) {
	.container,
	.Home {
		max-width: 500px;
		height: 100%;
		width: 100%;
	}
}
